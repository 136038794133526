import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import queryString from "query-string";
import { graphql } from "gatsby";
import scrollTo from "gatsby-plugin-smoothscroll";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Helmet from "react-helmet";
import ContactForm from "./contactForm";
import { Typography } from "@material-ui/core";
import PaperBackground from "./paperBackground.jpg";
import Layout from "../../components/layout/layout";
import Logos from "../../images/logos.png";
import Card1 from "./card3.svg";
import Card2 from "./card2.svg";
import Card3 from "./card1.svg";
import LineElem from "./lineElem.jpg";
import randomKey from "../../helpers/randomKey";

const useStyles = makeStyles((theme) => ({
  enlight: { color: theme.palette.primary.light },
  bS: {
    boxShadow: "0px 60px 50px rgba(67,67,68, 0.109804);",
    padding: 10,
    backgroundColor: theme.palette.background.default,
    borderRadius: 5,
  },
  header: {
    backgroundColor: theme.palette.background.medium,
    paddingTop: theme.spacing(12),
    paddingLeft: "5%",
    paddingRight: "5%",
    position: "relative",
  },
  mainBg: {
    backgroundColor: "#FCFCFC",
    backgroundImage: `url(${PaperBackground})`,
    backgroundSize: "auto 100px",
    backgroundRepeat: "repeat-x",
  },
  logos: {
    textAlign: "center",
    padding: "6em 0",
  },
  title: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(10),
  },
  cardTitle: {
    fontWeight: 600,
    fontSize: "1rem",
    position: "relative",
  },
  decoBox1: {
    width: 12,
    height: 12,
    left: "20%",
    top: "10%",
    backgroundColor: theme.palette.primary.light,
    position: "absolute",
    borderRadius: 2,
  },
  decoBox2: {
    width: 20,
    height: 20,
    left: "9%",
    top: "30%",
    backgroundColor: theme.palette.primary.dark,
    position: "absolute",
    borderRadius: 2,
  },
  decoBox3: {
    width: 20,
    height: 20,
    left: "65%",
    top: "5%",
    backgroundColor: theme.palette.primary.dark,
    position: "absolute",
    borderRadius: 2,
  },
  decoBox4: {
    width: 20,
    height: 20,
    right: "1%",
    top: "30%",
    backgroundColor: theme.palette.primary.dark,
    position: "absolute",
    borderRadius: 2,
  },
  decoBox5: {
    width: 12,
    height: 12,
    left: "80%",
    top: "35%",
    backgroundColor: theme.palette.primary.light,
    position: "absolute",
    borderRadius: 2,
  },
  weAreWrapper: {
    backgroundImage: `url(${LineElem})`,
    backgroundPositionY: "center",
    backgroundRepeat: "repeat-x",
    flexGrow: 1,
  },
  weAre: {
    color: "#14142A",
  },
  carteContainer: {
    paddingLeft: "5%",
    paddingRight: "5%",
    paddingBottom: "10%",
  },
  carte: {
    borderRadius: 5,
  },
}));

const Contact = ({
  data,
  location: { search },
  pageContext: { slug, langKey },
}) => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");
  const query = queryString.parse(search);
  useEffect(() => {
    if (window && query.object) {
      scrollTo("#contact-form");
    }
  }, [query]);
  const contents = data.allContactJson.nodes[0].contact[langKey][0];
  return (
    <Layout slug={slug} langKey={langKey} siteMetadata={data.site.siteMetadata}>
      <Helmet>
        <title>Contact us | Cyclope.ai</title>
      </Helmet>
      <Grid item>
        {matches && (
          <div className={classes.header} key={0}>
            {[1, 2, 3, 4, 5].map((idx) => (
              <div key={randomKey()} className={classes[`decoBox${idx}`]} />
            ))}
            <Grid container direction="row" spacing={6} justify="center">
              <Grid xs={12} className={classes.title}>
                <Typography variant="h1" align="center">
                  {"Let's"} <span className={classes.enlight}> connect</span> !
                </Typography>
                <Typography variant="body1" color="textPrimary" align="center">
                  Contact us and meet our team : we would be happy to get in
                  touch to discuss about
                  <br />
                  your daily challenges and see what value Cyclope.ai can bring
                  you
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Paper className={classes.bS}>
                  <Grid
                    container
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                  >
                    <Grid item>
                      <img src={Card1} alt="Try our AI on your datas" />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="h6"
                        color="textSecondary"
                        className={classes.cardTitle}
                      >
                        You are a Road Manager
                      </Typography>
                      <Typography variant="body1" color="textPrimary">
                        {"Try our AI models on"}
                        <br />
                        {"your data!"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper className={classes.bS}>
                  <Grid
                    container
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                  >
                    <Grid item>
                      <img src={Card2} alt="Intergrator information" />
                    </Grid>
                    <Grid item>
                      {" "}
                      <Typography
                        variant="h6"
                        color="textSecondary"
                        className={classes.cardTitle}
                      >
                        You are a System Integrator
                      </Typography>
                      <Typography variant="body1" color="textPrimary">
                        {"Let’s talk about your"}
                        <br />
                        {"clients needs!"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper className={classes.bS}>
                  <Grid
                    container
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                  >
                    <Grid item>
                      <img src={Card3} alt="Switch to smart road" />
                    </Grid>
                    <Grid item>
                      {" "}
                      <Typography
                        variant="h6"
                        color="textSecondary"
                        className={classes.cardTitle}
                      >
                        You are an Engeneering Firm
                      </Typography>
                      <Typography variant="body1" color="textPrimary">
                        {"Switch into the era of"}
                        <br />
                        {"designing Smart Road!"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justify="center"
              className={classes.logos}
              spacing={6}
            >
              <Grid item className={classes.weAreWrapper} />
              <Grid item>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={classes.cardTitle}
                >
                  We are already working with leading companies and public
                  authorities
                </Typography>
              </Grid>
              <Grid item className={classes.weAreWrapper} />
              <Grid item xs={12}>
                <img
                  src={Logos}
                  style={{ width: "80%", height: "80%" }}
                  alt="Cyclope trusted by companies"
                />
              </Grid>
            </Grid>
          </div>
        )}
        <div>
          <Container
            className={clsx({ [classes.mainBg]: matches })}
            maxWidth="xl"
          >
            <GoogleReCaptchaProvider reCaptchaKey="6LeFIL0UAAAAABNdWDt6OhKQ5yHJm-2dMDdiCvou">
              <ContactForm
                id="contactform"
                className={classes.bS}
                langKey={langKey}
                object={query.object}
                data={contents.form}
              />
            </GoogleReCaptchaProvider>
          </Container>
        </div>
      </Grid>
    </Layout>
  );
};

Contact.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export default Contact;

export const query = graphql`
  query ContactQueryEn {
    allContactJson {
      nodes {
        contact {
          en {
            message
            form {
              title
              rgpd
              required
              privacy
              objectItems {
                press
                jobs
                information
                demo_tunnelwatch
                demo_tollsense
                demo_counting
                demo_roadshare
              }
              object
              messagePlaceholder
              message
              fullNamePlaceholder
              fullName
              emailPlaceholder
              email
              btnLabel
              errorMessage
              successMessage
            }
            connect {
              content
              items {
                content
                title
              }
              title
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        author {
          twitter
        }
        siteTitle
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`;
